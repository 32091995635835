import React from 'react';


const Earncrypto = () => {
  return (
    window.location.replace('https://cryptoversehub.xyz/earn-crypto/')
  )

}

export default Earncrypto