import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Layout,Typography, Space } from 'antd';

import { Navbar, Exchanges, Homepage, CryptoDetails, Cryptocurrencies, News, Channel, Earncrypto, Swap, Spendcrypto, Giftcrypto} from './components';
import './App.css';
import 'antd/dist/reset.css';




const App = () => {
  return (
    <div className="app">
        <div className="navbar">
            <Navbar />

        </div>

        <div className="main">
            <Layout>
                <div className="routes">
                    <Switch>
                    <Route exact path="/">
                            <Homepage/>
                            </Route>
                    <Route exact path="/exchanges">
                            <Exchanges/>
                            </Route>
                    <Route exact path="/cryptocurrencies">
                            <Cryptocurrencies/>
                            </Route>  

                    <Route exact path="/crypto/:coinId">
                            <CryptoDetails/>
                            </Route>
                     <Route exact path="/Earncrypto">
                        <Earncrypto/>
                        </Route>
                     <Route exact path="/Swap">
                        <Swap/>
                        </Route>
                    <Route exact path="/Spendcrypto">
                        <Spendcrypto/>
                        </Route>
                    <Route exact path="/Giftcrypto">
                        <Giftcrypto/>
                        </Route>
                     <Route exact path="/news">
                            <News/>
                            </Route>
                    <Route exact path="/channel">
                        <Channel/>
                        </Route>
                    </Switch>
                </div>
            </Layout>

              

          <div className="footer">
             <Typography.Title  level={5} style={{color:'white', textAlign: 'center'}}>
                 Cryptoverse Media is owned by Green Quid Limited. Registered in England & Wales, Company Number: 13706438.<br />
                 All rights reserved
             </Typography.Title>
                 <Space>
                    <Link to="/">Home</Link>
                    <Link to="/cryptocurrencies">Crypto</Link>
                    <Link to="/Earncrypto">Earn</Link>
                    <Link to="/Swap">Swap</Link>
                    <Link to="/Spendcrypto">Spend</Link>
                    {/*<Link to="/exchanges">Exchanges</Link> */}
                    <Link to="/news">News</Link>
                    <Link to="/channel">Channel</Link>
                    </Space>

                   </div>
              </div>
        
        </div>
  )
}

export default App;